import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/GatsbyLayout';

import ContactForm from '../components/ContactForm';
import Helmet from '../components/Helmet';

const renderHelmet = () => (
  <Helmet
    title="Contact Us - Get to Meet Highest Performant Team in Europe | MCRO"
    description={`MCRO Tech contact details: romanian office Cluj Office Marin Preda 10-12,Cluj-Napoca, Romania, 400502.
     Reach us at +40 747 215 726, email address: contact@mcro.tech. Getting in touch with us or schedule a call to hear 
     more details about who we are as a company`}
    canonical
    canonicalPage="contact"
  />
);

const Contact = ({ location }) => (
  <Layout location={location}>
    {renderHelmet()}
    <h1 className="show-for-seo">
      Contact Us now and learn how we can help you achieve your goals and transform your business to face
      nowadays competitors using digital transformation and well managed remote teams.
    </h1>
    <div id="contact">
      <ContactForm title="Got a great project idea?" subtitle="Let’s get in touch." page="contact" />
    </div>
  </Layout>
);

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Contact;
